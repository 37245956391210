import React from "react"
import Moment from 'react-moment'
import {
  StyledEventPrimaryCard,
  EventHeader,
  EventFigure,
  EventFigureCaption,
  EventDate,
  EventLocation,
  EventCalendarCard
} from "./StyledPrimaryEventCard"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import parse from "html-react-parser"
import Icon from "../icon";
import WorkshopImage from "../workshop-image";


const PrimaryEventCard = props => {
  const {
    eventTitle,
    eventId,
    excerpt,
    workshopName,
    earlyBird,
    image,
    featuredImage,
    uri,
    eventType,
    datetime,
    datetimeend,
    eventLocation,
    timeZone,
    price,
    priceSale,
    currency,
    locationUrl,
    streetaddress,
    addresslocality,
    postalcode,
    addressregion,
    addresscountry,
    lastfewspaces,
    lastFewDaysEarlyBird,
    onlineandlive,
    tag,
    eventdescription,
  } = props

  let featuredImg = props.featuredImage

  let inputDateTimeStart = datetime
  if (inputDateTimeStart) {
    let fields = inputDateTimeStart.split('_')
    var date = fields[0];
    var time = fields[1];
    var timeNoSec = time.slice(0, -3);
  }

  let inputDateTimeEnd = datetimeend
  if (inputDateTimeEnd) {
    let fields = inputDateTimeEnd.split('_')
    var enddate = fields[0];
    var endtime = fields[1];
    var endtimeNoSec = time.slice(0, -3);
  }

  let eventDescription
  if (eventdescription != null) {
    eventDescription = eventdescription.replace(/(<([^>]+)>)/gi, "")
  }
  var workshopDate = new Date(date)
  var daysBefore21 = new Date(workshopDate)
  if (eventTitle.includes('Practice Day')) {
    daysBefore21.setDate(daysBefore21.getDate() - 6)
  } else {
    daysBefore21.setDate(daysBefore21.getDate() - 20)
  }
  var today = new Date()
  var Difference_In_Time = daysBefore21.getTime() - today.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  let freeEvent
  let dateTimeWithTimeZone
  if (timeZone === 'GMT') {
    dateTimeWithTimeZone = datetime + '-00:00'
  } else {
    dateTimeWithTimeZone = datetime + '-01:00'
  }

  let endDateTimeWithTimeZone
  if (timeZone === 'GMT') {
    endDateTimeWithTimeZone = datetimeend + '-00:00'
  } else {
    endDateTimeWithTimeZone = datetimeend + '-01:00'
  }

  if (price === 0) freeEvent = true
  let showProfile
  const fullUriWithParams = uri + '?' + 'datetime=' + datetime
  return (
    <StyledEventPrimaryCard
    >
      <Link to={uri} state={{
        eventId: eventId,
        uri: uri,
        datetime: datetime,
        workshopName: workshopName,
        eventTitle: eventTitle,
        excerpt: excerpt,
        eventType: eventType,
        eventLocation: eventLocation
      }}>
        <EventFigure>
          <WorkshopImage eventType={eventType} />
          {lastfewspaces &&
            <EventFigureCaption type={showProfile}><Icon name="notification" /><span>{lastfewspaces}</span></EventFigureCaption>
          }
          {lastFewDaysEarlyBird &&
            <EventFigureCaption type={showProfile}><Icon name="notification" /><span>{lastFewDaysEarlyBird}</span></EventFigureCaption>
          }
          {onlineandlive &&
            <EventFigureCaption type={showProfile}><Icon name="notification" /><span>Live in person &amp; Online</span></EventFigureCaption>
          }
          {Difference_In_Days <= 14 && Difference_In_Days > 0 && price != 0 && eventType !== 'qt-energy-boost' && 
            <EventFigureCaption type={showProfile}>
              <Icon name="notification" />
              {Math.floor(Difference_In_Days) === 0 ? ' Earlybird: Last day to save' : <span>Earlybird: {Math.floor(Difference_In_Days)} {Math.floor(Difference_In_Days) === 1 ? 'day' : 'days'} left</span>}
            </EventFigureCaption>
          }
        </EventFigure>

        <EventHeader>
          {workshopName ? parse(workshopName) : parse(eventTitle)}
        </EventHeader>

        <EventDate className="start-date-time" content={dateTimeWithTimeZone}>
          <Moment format="dddd, MMM, Do">{date}</Moment>
          {' '}{timeNoSec} {' '} {timeZone}
        </EventDate>

        <EventDate className="end-date-time hidden" content={endDateTimeWithTimeZone} ariaHidden="true">
          <Moment format="dddd, MMM, Do">{enddate}</Moment>
        </EventDate>
        {eventdescription !== null &&
          <span className="eventDescription" ariaHidden="true">{eventDescription}</span>
        }

        <EventCalendarCard ariaHidden="true">
          <Moment className="event-month" element="span" format="MMM">{date}</Moment>
          <Moment className="event-day" element="span" format="D">{date}</Moment>
        </EventCalendarCard>

        {eventLocation === 'Zoom' ?
          <>
            <EventLocation className="event-location 123">{eventLocation}</EventLocation>
          </>
          :
          <>
            <EventLocation className="event-location 345">
              <span className="hidden">{locationUrl}</span>
              {eventLocation}, <span>
                <span>{addresslocality}</span>, <span>{addressregion}</span>, <span>{postalcode}</span>
              </span>

            </EventLocation>
            
          </>
        }
        {price !== null &&
        <div className="event-price">
              {earlyBird && priceSale !== null && eventType !== 'qt-energy-boost' ? 
                <>
                  Earlybird: <span>{currency}{priceSale}</span> <span className="event-fullprice">{currency}{price}</span>
                </>
                :
                <><span>{currency}{props.price}</span></>
              }
            </div>
        }
      </Link>
    </StyledEventPrimaryCard>
  );
}

export default PrimaryEventCard
